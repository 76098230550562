'use client';

import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import OrderRecordAndActionStateHandler from 'src/utils/OrderRecordAndActionMatcher';

export const OrderMatcherContext =
	createContext<OrderRecordAndActionStateHandler>(undefined);

const OrderRecordMatcherProvider = (props: PropsWithChildren<any>) => {
	const [handler, setHandler] = useState(undefined);

	const setDriftStore = useDriftStore((s) => s.set);
	const setAccountStore = useDriftAccountStore((s) => s.set);
	const getAccountStore = useDriftAccountStore((s) => s.get);
	const isConnected = useWalletIsConnected();

	useEffect(() => {
		if (!setAccountStore || !setDriftStore) return;

		setHandler(
			new OrderRecordAndActionStateHandler(
				setDriftStore,
				setAccountStore,
				getAccountStore
			)
		);
		return;
	}, [setDriftStore, setAccountStore, isConnected]);

	return (
		<OrderMatcherContext value={handler}>{props.children}</OrderMatcherContext>
	);
};

export default OrderRecordMatcherProvider;

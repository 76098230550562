import { ENUM_UTILS } from '@drift/common';
import { PublicKey } from '@solana/web3.js';
import UI_UTILS from 'src/utils/uiUtils';
import { Account, Lightning, Phoenix, Drift } from '@drift-labs/icons';
import { MarketType, OrderActionExplanation } from '@drift-labs/sdk';
import OpenBookIcon from './Icons/OpenBookIcon';
import Tooltip from './Tooltip/Tooltip';

type CounterPartyProps = {
	counterparty: string;
	marketType: MarketType;
	actionExplanation: OrderActionExplanation;
	isGenericTrade?: boolean;
	isTaker?: boolean;
};

export const getCounterpartyIcon = (
	args: CounterPartyProps,
	color = 'var(--text-label)'
) => {
	const isAmmTrade =
		!args.counterparty ||
		ENUM_UTILS.match(
			args.actionExplanation,
			OrderActionExplanation.ORDER_FILLED_WITH_AMM
		);
	const isJitTrade = ENUM_UTILS.match(
		args.actionExplanation,
		OrderActionExplanation.ORDER_FILLED_WITH_MATCH_JIT
	);
	const isPerp = ENUM_UTILS.match(args.marketType, MarketType.PERP);

	if (isJitTrade) {
		return <Lightning color={'url(#prize-gradient)'} />;
	} else if (!isAmmTrade) {
		return <Account color={color} />;
	} else if (isPerp) {
		return <Drift color={color} />;
	} else {
		if (
			ENUM_UTILS.match(
				args.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_SERUM
			) ||
			ENUM_UTILS.match(
				args.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_OPENBOOK_V2
			)
		) {
			return <OpenBookIcon color={color} />;
		} else if (
			ENUM_UTILS.match(
				args.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_PHOENIX
			)
		) {
			return <Phoenix color={color} />;
		}
	}

	return null;
};

const Counterparty = (props: CounterPartyProps) => {
	const isAmmTrade =
		!props.counterparty ||
		ENUM_UTILS.match(
			props.actionExplanation,
			OrderActionExplanation.ORDER_FILLED_WITH_AMM
		);

	const isJitTrade = ENUM_UTILS.match(
		props.actionExplanation,
		OrderActionExplanation.ORDER_FILLED_WITH_MATCH_JIT
	);
	const isPerp = ENUM_UTILS.match(props.marketType, MarketType.PERP);
	const ammCounterparty = isPerp
		? 'Drift AMM'
		: ENUM_UTILS.match(
				props.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_SERUM
		  ) ||
		  ENUM_UTILS.match(
				props.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_OPENBOOK_V2
		  )
		? 'OpenBook'
		: 'Phoenix';

	const tooltipText = isJitTrade
		? props.isTaker || props.isGenericTrade
			? `Trade filled by [${UI_UTILS.abbreviateAddress(
					new PublicKey(props.counterparty)
			  )}] with just-in-time liquidity`
			: `[${UI_UTILS.abbreviateAddress(
					new PublicKey(props.counterparty)
			  )}] was filled by just-in-time liquidity`
		: isAmmTrade
		? `Trade filled by ${ammCounterparty}`
		: `${
				props.isGenericTrade ? 'Trade filled by' : 'Counterparty'
		  } [${UI_UTILS.abbreviateAddress(new PublicKey(props.counterparty))}]`;

	return (
		<div
			className={`${isAmmTrade ? '' : 'cursor-pointer hover:opacity-80'}`}
			onClick={() => {
				!isAmmTrade &&
					UI_UTILS.openUrl(`/overview?userAccount=${props.counterparty}`);
			}}
		>
			<Tooltip placement="top" content={tooltipText}>
				{getCounterpartyIcon(props)}
			</Tooltip>
		</div>
	);
};

export default Counterparty;

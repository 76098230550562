import { MarketType, PublicKey } from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	Serializer,
	UIMatchedOrderRecordAndAction,
	UISerializableOrderActionRecord,
	UISerializableOrderRecord,
	sortUIMatchedOrderRecordAndAction,
	sortUIOrderActionRecords,
} from '@drift/common';
import { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import { DriftAccountsStore } from 'src/stores/useDriftAccountsStore';

/**
 *
 * Need unmatched OrderRecords
 * Unmatched ActionRecords
 * Matching-Key generation for ORder Records + Action Records
 * Matched Action Records lookup < - > Action Record Key generation
 *
 */

class OrderRecordAndActionStateHandler {
	// Stores all incoming order records
	orderRecordCache: Map<string, UISerializableOrderRecord> = new Map();
	matchedActionRecordCache: Map<string, UISerializableOrderActionRecord> =
		new Map();
	unmatchedActions: UISerializableOrderActionRecord[] = [];

	setDriftStore: DriftStore['set'];
	setAccountStore: DriftAccountsStore['set'];
	getState: DriftAccountsStore['get'];

	constructor(
		setA: DriftStore['set'],
		setB: DriftAccountsStore['set'],
		getA: DriftAccountsStore['get']
	) {
		this.setDriftStore = setA;
		this.setAccountStore = setB;
		this.getState = getA;
	}

	private getOrderRecordMatchingKey = (record: UISerializableOrderRecord) => {
		return `_${record.user.toString()}_${ENUM_UTILS.toStr(
			record.order.marketType
		)}_${record.order.marketIndex.toString()}_${record.order.orderId.toString()}`;
	};
	private getTakerActionMatchingKey = (
		record: UISerializableOrderActionRecord
	) => {
		return `_${record.taker.toString()}_${ENUM_UTILS.toStr(
			record.marketType
		)}_${record.marketIndex.toString()}_${record.takerOrderId.toString()}`;
	};
	private getMakerActionMatchingKey = (
		record: UISerializableOrderActionRecord
	) => {
		return `_${record.maker.toString()}_${ENUM_UTILS.toStr(
			record.marketType
		)}_${record.marketIndex.toString()}_${record.makerOrderId.toString()}`;
	};

	private getTakerActionUniquenessKey = (
		record: UISerializableOrderActionRecord
	) => {
		return `_${record.taker.toString()}_${ENUM_UTILS.toStr(
			record.marketType
		)}_${record.marketIndex.toString()}_${record.takerOrderId.toString()}_${
			record.fillRecordId
		}_${ENUM_UTILS.toStr(record.action)}_${record.txSig.toString()}`;
	};

	private getMakerActionUniquenessKey = (
		record: UISerializableOrderActionRecord
	) => {
		return `_${record.maker.toString()}_${ENUM_UTILS.toStr(
			record.marketType
		)}_${record.marketIndex.toString()}_${record.makerOrderId.toString()}_${
			record.fillRecordId
		}_${ENUM_UTILS.toStr(record.action)}_${record.txSig.toString()}`;
	};

	private actionAlreadyMatched = (
		actionRecord: UISerializableOrderActionRecord
	) => {
		if (actionRecord.taker) {
			const takerMatchingKey = this.getTakerActionUniquenessKey(actionRecord);
			if (this.matchedActionRecordCache.get(takerMatchingKey)) return true;
		}

		if (actionRecord.maker) {
			const makerMatchingKey = this.getMakerActionUniquenessKey(actionRecord);
			if (this.matchedActionRecordCache.get(makerMatchingKey)) return true;
		}

		return false;
	};

	private handleMatchedRecordsAndAddToStore(
		matchedRecords: UIMatchedOrderRecordAndAction[]
	) {
		const filteredMatchedRecords = matchedRecords.filter(
			(matchedRecord) => !this.actionAlreadyMatched(matchedRecord.actionRecord)
		);

		filteredMatchedRecords.forEach((matchedRecord) =>
			this.addMatchedActionRecordToCache(matchedRecord.actionRecord)
		);

		const matchedUserKeys = filteredMatchedRecords
			.map((matchedRecord) =>
				this.findAccountToAppendTo(matchedRecord.orderRecord.user)
			)
			.filter((result) => !!result);

		// Add Order History
		this.setAccountStore((s) => {
			matchedUserKeys.forEach((userKey) => {
				const currentOrderHistory =
					s.accounts[userKey].orderHistory.loadedUserOrderHistory;

				const recordsToAdd = filteredMatchedRecords.filter(
					(record) =>
						userKey === this.findAccountToAppendTo(record.orderRecord.user)
				);

				s.accounts[userKey].orderHistory.marketOrderCounts['spot'] +=
					recordsToAdd.filter((record) =>
						ENUM_UTILS.match(
							record.orderRecord.order.marketType,
							MarketType.SPOT
						)
					).length;

				s.accounts[userKey].orderHistory.marketOrderCounts['perp'] +=
					recordsToAdd.filter((record) =>
						ENUM_UTILS.match(
							record.orderRecord.order.marketType,
							MarketType.PERP
						)
					).length;

				s.accounts[userKey].orderHistory.loadedUserOrderHistory =
					sortUIMatchedOrderRecordAndAction([
						...currentOrderHistory,
						...recordsToAdd,
					]);
			});
		});
	}

	private createMatchedRecord = (
		actionRecord: UISerializableOrderActionRecord,
		orderRecord: UISerializableOrderRecord
	): UIMatchedOrderRecordAndAction => ({
		actionRecord: actionRecord,
		orderRecord: orderRecord,
	});

	private actionMatchesRecordCacheId = (
		action: UISerializableOrderActionRecord,
		recordCacheId: string
	) => {
		if (action.maker) {
			if (this.getMakerActionMatchingKey(action) === recordCacheId) return true;
		}

		if (action.taker) {
			if (this.getTakerActionMatchingKey(action) === recordCacheId) return true;
		}

		return false;
	};

	private findAccountToAppendTo = (pubKey: PublicKey): string => {
		const findAcct = Object.values(this.getState().accounts).find((acct) =>
			acct.pubKey?.equals(pubKey)
		);

		return findAcct?.userKey;
	};

	private addMatchedActionRecordToCache = (
		unmatchedAction: UISerializableOrderActionRecord
	) => {
		if (unmatchedAction.maker) {
			this.matchedActionRecordCache.set(
				this.getMakerActionUniquenessKey(unmatchedAction),
				unmatchedAction
			);
		}

		if (unmatchedAction.taker) {
			this.matchedActionRecordCache.set(
				this.getTakerActionUniquenessKey(unmatchedAction),
				unmatchedAction
			);
		}
	};

	public getOrderUniquenessKey = (record) => {
		return record.taker
			? `taker${this.getTakerActionUniquenessKey(record)}`
			: `maker${this.getMakerActionUniquenessKey(record)}`;
	};

	public ordersAreIdentical = (
		recordA: UISerializableOrderActionRecord,
		recordB: UISerializableOrderActionRecord
	) => {
		return (
			this.getOrderUniquenessKey(recordA) ===
			this.getOrderUniquenessKey(recordB)
		);
	};

	public getSortedDedupedTrimmedRecords = (
		records: UISerializableOrderActionRecord[],
		cap: number
	) => {
		return sortUIOrderActionRecords(
			[...new Set(records.map(Serializer.Serialize.UIOrderActionRecord))].map(
				Serializer.Deserialize.UIOrderActionRecord
			)
		).slice(0, cap) as UISerializableOrderActionRecord[];
	};

	public addOrderRecord(record: UISerializableOrderRecord) {
		const cacheId = this.getOrderRecordMatchingKey(record);

		const newlyMatchedActions = this.unmatchedActions.filter((action) => {
			return this.actionMatchesRecordCacheId(action, cacheId);
		});

		// Clear newly matched actions from unmatched list
		if (newlyMatchedActions.length > 0) {
			this.unmatchedActions = this.unmatchedActions.filter((action) => {
				return !this.actionMatchesRecordCacheId(action, cacheId);
			});
		}

		// Handle newly matched records
		for (const action of newlyMatchedActions) {
			const matchedRecord = this.createMatchedRecord(action, record);

			this.handleMatchedRecordsAndAddToStore([matchedRecord]);
		}

		// Add the new record to the record cache
		this.orderRecordCache.set(cacheId, record);
	}

	public addActionRecord(newAction: UISerializableOrderActionRecord) {
		if (this.actionAlreadyMatched(newAction)) {
			return;
		}

		const unmatchedActionsToCheck = [...this.unmatchedActions, newAction];
		const newUnmatchedActions = [];
		const matchedMakerRecords = [];
		const matchedTakerRecords = [];

		// For each currently unmatched action, check if it matches new order state and removed from unmatched state if so
		unmatchedActionsToCheck.forEach((unmatchedAction) => {
			let foundMatch = false;

			if (unmatchedAction.maker) {
				const makerMatchingKey =
					this.getMakerActionMatchingKey(unmatchedAction);

				const matchingOrderRecord = this.orderRecordCache.get(makerMatchingKey);

				// Handle Matched Maker
				if (matchingOrderRecord) {
					foundMatch = true;

					const matchedMakerRecord = this.createMatchedRecord(
						unmatchedAction,
						matchingOrderRecord
					);

					matchedMakerRecords.push(matchedMakerRecord);
				}
			}

			if (unmatchedAction.taker) {
				const takerMatchingKey =
					this.getTakerActionMatchingKey(unmatchedAction);

				const matchingOrderRecord = this.orderRecordCache.get(takerMatchingKey);

				// Handle Matched Taker
				if (matchingOrderRecord) {
					foundMatch = true;

					const matchedTakerRecord = this.createMatchedRecord(
						unmatchedAction,
						matchingOrderRecord
					);

					matchedTakerRecords.push(matchedTakerRecord);
				}
			}

			this.handleMatchedRecordsAndAddToStore(matchedMakerRecords);
			this.handleMatchedRecordsAndAddToStore(matchedTakerRecords);

			// If no match, add to unmatched actions
			if (!foundMatch) {
				newUnmatchedActions.push(unmatchedAction);
				return;
			}
		});

		// Update unmatched actions
		this.unmatchedActions = newUnmatchedActions;
	}
}

export default OrderRecordAndActionStateHandler;

import { ArrowRight } from '@drift-labs/icons';
import React, { MouseEvent } from 'react';

const UIHelpTextLink = (props: {
	href?: string;
	onClick?: (e?: MouseEvent) => void;
	text: string;
	className?: string;
	popupIgnoreClickEvent?: boolean;
}) => {
	return (
		<a
			href={props.href ? `${props.href}` : undefined}
			target="_blank"
			rel="noopener noreferrer"
			className={`hover:cursor-pointer text-interactive-link flex items-center space-x-2 ${
				props.className ?? ``
			}`}
			ignore-click-event={props.popupIgnoreClickEvent ? 'true' : 'false'}
			onClick={(e) => {
				props?.onClick?.(e);
				e.stopPropagation();
			}}
		>
			<div ignore-click-event={props.popupIgnoreClickEvent ? 'true' : 'false'}>
				{props.text}
			</div>
			<ArrowRight
				size={14}
				ignore-click-event={props.popupIgnoreClickEvent ? 'true' : 'false'}
			/>
		</a>
	);
};

export default UIHelpTextLink;

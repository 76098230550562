'use client';

import { BN, ONE, PRICE_PRECISION } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import { useCallback } from 'react';

export const getTickSizeDecimalPlaces = (tickSize: BN) => {
	// tick size refers to the price precision
	// e.g. $1 = 1_000_000 decimals in USDC. tickSize of 100 -> 1_000_000 / 100 = 10_000 decimals = 0.0001 USDC per tick
	const decimalPlaces = Math.max(
		0,
		Math.floor(Math.log10(PRICE_PRECISION.div(tickSize).toNumber()))
	);

	return decimalPlaces;
};

const useGetTickSizeForMarket = () => {
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const driftClientIsReady = useDriftClientIsReady();

	const getTickSizeForMarket = useCallback(
		(marketId: MarketId) => {
			if (!driftClientIsReady) {
				return ONE;
			}

			if (marketId.isPerp) {
				const perpMarketAccount = driftClient.getPerpMarketAccount(
					marketId.marketIndex
				);
				return perpMarketAccount?.amm?.orderTickSize ?? ONE;
			} else {
				const spotMarketAccount = driftClient.getSpotMarketAccount(
					marketId.marketIndex
				);
				return spotMarketAccount?.orderTickSize ?? ONE;
			}
		},
		[driftClient, driftClientIsReady]
	);

	return getTickSizeForMarket;
};

export default useGetTickSizeForMarket;

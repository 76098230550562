import InfoMessage from './TradeForm/InfoMessage';
import Text from './Text/Text';
import CheckboxInput from './CheckboxInput';
import Env from 'src/environmentVariables/EnvironmentVariables';

export default function PriceDivergenceWarningMessage({
	hasAgreedToPriceDivergence,
	setHasAgreedToPriceDivergence,
	priceDivergencePct,
}: {
	hasAgreedToPriceDivergence: boolean;
	setHasAgreedToPriceDivergence: React.Dispatch<React.SetStateAction<boolean>>;
	priceDivergencePct?: string;
}) {
	return (
		<InfoMessage
			type={'warn'}
			message={
				<div>
					<Text.BODY3>
						{`Est. entry price is ${
							priceDivergencePct ?? `>${Env.priceDivergenceWarningThreshold}`
						}% from oracle price. Proceeding with this order may result in poor fills.`}
					</Text.BODY3>
					<CheckboxInput
						className="mt-1 text-text-default"
						label={`I understand the risk`}
						checked={hasAgreedToPriceDivergence}
						onChange={() => {
							setHasAgreedToPriceDivergence((prev) => !prev);
						}}
						secondaryStyle
					/>
				</div>
			}
		/>
	);
}

'use client';

import {
	BigNum,
	BN,
	PositionDirection,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import NumLib from 'src/utils/NumLib';
import useDriftClientIsReady from './useDriftClientIsReady';
import useUserAccountIsReady from './useUserAccountIsReady';
import useWalletIsConnected from './useWalletIsConnected';

interface MaxAvailableParams {
	marketIndex: number;
	isSpot: boolean;
	side: 'buy' | 'sell';
	entryPrice: number;
}

/**
 * Returns max available base and quote assets for a trade
 * @param marketIndex
 * @param isSpot
 * @param side
 * @param entryPrice - should be either the estimated entry price for market orders, or the limit price for limit orders
 */
const useMaxAvailable = ({
	marketIndex,
	isSpot,
	side,
	entryPrice,
}: MaxAvailableParams) => {
	const connected = useWalletIsConnected();
	const driftClientIsReady = useDriftClientIsReady();
	const userAccountIsReady = useUserAccountIsReady();
	const userKey = useDriftAccountStore((s) => s.currentUserKey);
	const currentAccountClient = useDriftAccountStore(
		(s) => s.accounts[userKey]?.client
	);
	const marginInfo = useDriftAccountStore(
		(s) => s.accounts[userKey]?.marginInfo
	);
	const freeCollateral = NumLib.formatBn.fromQuote(
		marginInfo?.freeCollateral ?? new BN(0)
	);
	const [maxQuoteAvailable, setMaxQuoteAmountAvailable] = useState(0);
	const [maxBaseAvailable, setMaxBaseAmountAvailable] = useState(0);

	useEffect(() => {
		if (!userAccountIsReady || !connected) {
			setMaxQuoteAmountAvailable(0);
			setMaxBaseAmountAvailable(0);
			return;
		}

		let maxQuoteAllowable: BN;

		if (isSpot) {
			maxQuoteAllowable = currentAccountClient.getMaxTradeSizeUSDCForSpot(
				marketIndex,
				side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
			);
		} else {
			maxQuoteAllowable = currentAccountClient.getMaxTradeSizeUSDCForPerp(
				marketIndex,
				side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
			).tradeSize;
		}

		const maxQuoteNumber = BigNum.from(
			maxQuoteAllowable,
			QUOTE_PRECISION_EXP
		).toNum();
		const maxBaseNumber = maxQuoteNumber / entryPrice;

		setMaxQuoteAmountAvailable(maxQuoteNumber);
		setMaxBaseAmountAvailable(maxBaseNumber);
	}, [
		!!currentAccountClient,
		freeCollateral,
		marketIndex,
		side,
		entryPrice,
		userAccountIsReady,
		driftClientIsReady,
		connected,
	]);

	return {
		maxQuoteAvailable,
		maxBaseAvailable,
	};
};

export default useMaxAvailable;

'use client';

import { useLocalStorageStringState } from './useLocalStorageState';

/**
 * Maintains a local storage state for whether to display the date as a timestamp or "x days/weeks/months ago"
 */
const useDateDisplayState = () => {
	const [displayDatesAsTimestampString, setDisplayDatesAsTimestampString] =
		useLocalStorageStringState('displayDatesAsTimestamp', 'false');
	const displayDatesAsTimestamp = displayDatesAsTimestampString === 'true';

	const toggleDateDisplayType = () => {
		if (displayDatesAsTimestampString === 'true') {
			setDisplayDatesAsTimestampString('false');
		} else {
			setDisplayDatesAsTimestampString('true');
		}
	};

	return {
		displayDatesAsTimestamp,
		displayDatesAsTimestampString,
		setDisplayDatesAsTimestampString,
		toggleDateDisplayType,
	};
};

export default useDateDisplayState;

'use client';

import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	OpenPosition,
	UIMarket,
	UIOrderType,
} from '@drift/common';
import useMemoizedOraclePrice from './useMemoizedOraclePrice';
import { useMemo } from 'react';
import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	MAX_PREDICTION_PRICE,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	PositionDirection,
	User,
	ZERO,
	calculateEstimatedEntryPriceWithL2,
} from '@drift-labs/sdk';
import UI_UTILS from 'src/utils/uiUtils';
import useL2StateForMarket from './useL2StateForMarket';
import NumLib from 'src/utils/NumLib';
import useMarkPrice from './useMarkPrice';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	getMessageInfoForOrderViolation,
	getPriceBoxHeading,
	getShouldShowSecondaryPriceBox,
} from 'src/utils/trade';
import { useMarketStepSize } from './useMarketStepSize';
import { PERP_MARKETS_LOOKUP } from '../environmentVariables/EnvironmentVariables';
import { MAX_PREDICTION_PRICE_BIGNUM } from 'src/constants/math';

type ClosePositionStateEngineProps = {
	market: number;
	selectedOrderType: UIOrderType;
	priceBoxValue: number;
	secondaryPriceBoxValue: number;
	isLong: boolean;
	currentPositionDirection: 'long' | 'short';
	openPositionInfo: OpenPosition;
	takerFeeBps: number;
	targetMarketTickSizeExponent: number;
	baseAmountOfClose: BigNum;
	user: User;
};

export function getQuotePriceToUse(
	selectedOrderType: UIOrderType,
	orderDirection: 'long' | 'short' | 'buy' | 'sell',
	marketPrice: BigNum,
	currentOraclePrice: BigNum,
	limitOrTriggerPrice: BigNum,
	triggerMarketImmediateExecutionPrice: BigNum,
	triggerLimitPrice: BigNum
) {
	let priceToUse: BigNum;

	const isBuyOrder = orderDirection === 'long' || orderDirection === 'buy';
	const isSellOrder = orderDirection === 'short' || orderDirection === 'sell';

	switch (selectedOrderType) {
		case 'market':
			priceToUse = marketPrice;
			break;
		case 'limit':
			priceToUse = limitOrTriggerPrice;

			// long position - short close - current market price above limit price will lead to immediate execution of market order
			if (isSellOrder && marketPrice.gte(limitOrTriggerPrice)) {
				priceToUse = marketPrice;
			}

			// short position - long close - current market price below limit price will lead to immediate execution of market order
			if (isBuyOrder && marketPrice.lte(limitOrTriggerPrice)) {
				priceToUse = marketPrice;
			}

			break;
		case 'stopMarket':
			priceToUse = limitOrTriggerPrice;

			// short position stop loss - current oracle price above trigger price will lead to immediate execution of market order
			if (isBuyOrder && currentOraclePrice.gte(limitOrTriggerPrice)) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}

			// long position stop loss - current oracle price below trigger price will lead to immediate execution of market order
			if (isSellOrder && currentOraclePrice.lte(limitOrTriggerPrice)) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}

			break;
		case 'takeProfitMarket':
			priceToUse = limitOrTriggerPrice;

			// short position - take profit means price below entry - current oracle price below trigger price will lead to immediate execution of market order
			if (isBuyOrder && currentOraclePrice.lte(limitOrTriggerPrice)) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}

			// long position - take profit means price above entry - current oracle price above trigger price will lead to immediate execution of market order
			if (isSellOrder && currentOraclePrice.gte(limitOrTriggerPrice)) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}
			break;
		case 'stopLimit':
			priceToUse = triggerLimitPrice;

			// short position stop loss limit
			// - current oracle price above trigger price will lead to immediate placement of limit order (doesn't affect pnl)
			// - long close order - current market price below limit order will lead to immediate execution of market order
			if (
				isBuyOrder &&
				currentOraclePrice.gte(limitOrTriggerPrice) &&
				marketPrice.lte(limitOrTriggerPrice)
			) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}

			// long position stop loss limit
			// - current oracle price below trigger price will lead to immediate execution of market order (doesn't affect pnl)
			// - short close limit order - current market above below limit order will lead to immediate execution of market order
			if (
				isSellOrder &&
				currentOraclePrice.lte(limitOrTriggerPrice) &&
				marketPrice.gte(limitOrTriggerPrice)
			) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}
			break;
		case 'takeProfitLimit':
			priceToUse = triggerLimitPrice;

			// short position take profit limit
			// - current oracle price below trigger price will lead to immediate execution of market order (doesn't affect pnl)
			// - long close limit order - current market price below limit order will lead to immediate execution of market order
			if (
				isBuyOrder &&
				currentOraclePrice.lte(limitOrTriggerPrice) &&
				marketPrice.lte(triggerLimitPrice)
			) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}

			// long position take profit limit
			// - current oracle price above trigger price will lead to immediate execution of market order  (doesn't affect pnl)
			// - short close limit order - current market price above limit order will lead to immediate execution of market order
			if (
				isSellOrder &&
				currentOraclePrice.gte(limitOrTriggerPrice) &&
				marketPrice.gte(triggerLimitPrice)
			) {
				priceToUse = triggerMarketImmediateExecutionPrice;
			}
			break;
		case 'oracle':
			priceToUse = marketPrice;
			break;
		case 'oracleLimit':
			priceToUse = limitOrTriggerPrice.add(currentOraclePrice);
			break;
		default:
			console.warn(
				'Unhandled order type in Close Position Popup',
				selectedOrderType
			);
			return BigNum.zero(PRICE_PRECISION_EXP);
	}

	return priceToUse;
}

const useClosePositionStateEngine = ({
	market,
	selectedOrderType,
	priceBoxValue,
	secondaryPriceBoxValue,
	isLong,
	currentPositionDirection,
	takerFeeBps,
	openPositionInfo,
	targetMarketTickSizeExponent,
	baseAmountOfClose,
	user,
}: ClosePositionStateEngineProps) => {
	const uiMarket = UIMarket.createPerpMarket(market);
	const marketId = uiMarket.marketId;
	const marketSymbol = PERP_MARKETS_LOOKUP[marketId.marketIndex].symbol;
	const closePositionOrderDirection =
		currentPositionDirection === 'long' ? 'short' : 'long';

	const stepSizeNum = useMarketStepSize(marketId);
	const closeSizeNum = baseAmountOfClose.toNum();

	// App Utility Hooks
	const currentOraclePrice = useMemoizedOraclePrice(marketId);
	const l2State = useL2StateForMarket(
		marketId.marketIndex,
		marketId.marketType
	);
	const currentMarkPrice = useMarkPrice(marketId);
	const tradeFormSlippageTolerance = useDriftStore(
		(s) => s.tradeForm.slippageTolerance
	);

	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: isLong, // closing a position is opposite of the current position
		})
	);

	const prizeBoxValueBigNum = BigNum.fromPrint(
		priceBoxValue.toString(),
		PRICE_PRECISION_EXP
	);
	const secondaryPriceBoxValueBigNum = BigNum.fromPrint(
		secondaryPriceBoxValue.toString(),
		PRICE_PRECISION_EXP
	);
	const quoteSizeOfClose = getQuoteAmountOfClose().abs();

	// Price Box Heading
	const priceBoxHeading = getPriceBoxHeading(selectedOrderType);

	// Show Secondary Price Box
	const showSecondaryPriceBox =
		getShouldShowSecondaryPriceBox(selectedOrderType);

	const errorMessageInfo = getMessageInfoForOrderViolation(
		selectedOrderType,
		false,
		priceBoxValue,
		currentMarkPrice?.toNum() ?? 0,
		false,
		secondaryPriceBoxValue,
		isLong ? 'long' : 'short',
		currentPositionDirection,
		currentOraclePrice.toNum(),
		marketSymbol,
		isSellPredictionMarket,
		l2State,
		false,
		closeSizeNum,
		stepSizeNum
	);

	const {
		liquidationPrice,
		exitPriceNum,
		exitPrice,
		estPriceImpact,
		pnlBeforeFees,
		notionalSizeAtExit,
		notionalSizeAtEntry,
		takerFee,
		realisedPnl,
	} = useMemo(() => {
		// # Initial Values
		let estExitPriceUIVal = '';
		let liqPriceVal = '';
		let priceImpactUIVal = '';

		let newEstimatedPnl = 0;
		let newEstimatedPnlBeforeFees = 0;
		let newEstimatedTakerFee = 0;
		let newNotionalSizeAtEntry = 0;
		let newNotionalSizeAtExit = 0;

		// # Base Calculations
		const {
			entryPrice: estExitPriceOnImmediateExecution,
			priceImpact: priceImpactOnImmediateExecution,
		} = calculateEstimatedEntryPriceWithL2(
			'base',
			baseAmountOfClose.val,
			openPositionInfo.direction === 'long'
				? PositionDirection.SHORT
				: PositionDirection.LONG,
			BASE_PRECISION,
			l2State
		);
		const estExitPriceOnImmediateExecutionBigNum = BigNum.from(
			estExitPriceOnImmediateExecution,
			PRICE_PRECISION_EXP
		);

		// # Calculate Price Impact
		const priceImpactBigNum = BigNum.from(
			priceImpactOnImmediateExecution ? priceImpactOnImmediateExecution : 0,
			PRICE_PRECISION_EXP
		).shift(new BN(2), true);
		priceImpactUIVal = priceImpactBigNum.gtZero()
			? priceImpactBigNum.toFixed(4)
			: '0';

		// # Calculate Est Exit Price
		const isZeroClosePosition = baseAmountOfClose.eqZero();
		const closeDirectionOrderbookTopPrice =
			(openPositionInfo.direction === 'long'
				? l2State.asks[0]?.price
				: l2State.bids[0]?.price) ?? ZERO;

		let estExitPriceBigNum = getQuotePriceToUse(
			selectedOrderType,
			closePositionOrderDirection,
			isZeroClosePosition
				? BigNum.from(closeDirectionOrderbookTopPrice, PRICE_PRECISION_EXP)
				: estExitPriceOnImmediateExecutionBigNum,
			currentOraclePrice,
			prizeBoxValueBigNum,
			estExitPriceOnImmediateExecutionBigNum,
			secondaryPriceBoxValueBigNum
		);

		if (isSellPredictionMarket && selectedOrderType === 'market') {
			estExitPriceBigNum = MAX_PREDICTION_PRICE_BIGNUM.sub(estExitPriceBigNum);
		}

		estExitPriceUIVal = UI_UTILS.prettyPrintPriceBasedOnTick(
			estExitPriceBigNum,
			targetMarketTickSizeExponent
		);

		// # Calculate Realised Pnl
		const {
			estimatedProfit,
			estimatedProfitBeforeFees,
			estimatedTakerFee,
			notionalSizeAtEntry,
			notionalSizeAtExit,
		} = COMMON_UI_UTILS.calculatePotentialProfit({
			currentPositionSize: BigNum.from(
				openPositionInfo.baseSize,
				BASE_PRECISION_EXP
			).abs(),
			currentPositionDirection: ENUM_UTILS.toObj(openPositionInfo.direction),
			tradeDirection:
				openPositionInfo.direction === 'long'
					? PositionDirection.SHORT
					: PositionDirection.LONG,
			exitBaseSize: baseAmountOfClose,
			exitPrice: estExitPriceBigNum,
			currentPositionEntryPrice: BigNum.from(
				isSellPredictionMarket
					? MAX_PREDICTION_PRICE.sub(openPositionInfo.entryPrice)
					: openPositionInfo.entryPrice,
				PRICE_PRECISION_EXP
			),
			slippageTolerance: tradeFormSlippageTolerance,
			isMarketOrder: selectedOrderType == 'market',
			takerFeeBps,
		});

		newEstimatedPnl =
			(isSellPredictionMarket
				? estimatedProfit.neg()
				: estimatedProfit
			)?.toNum() ?? 0;
		newEstimatedPnlBeforeFees =
			(isSellPredictionMarket
				? estimatedProfitBeforeFees.neg()
				: estimatedProfitBeforeFees
			)?.toNum() ?? 0;
		newEstimatedTakerFee = estimatedTakerFee?.toNum() ?? 0;
		newNotionalSizeAtEntry = notionalSizeAtEntry?.toNum() ?? 0;
		newNotionalSizeAtExit = notionalSizeAtExit?.toNum() ?? 0;

		// # Calculate Liquidation Price
		let newLiquidationPrice = user.liquidationPriceAfterClose(
			openPositionInfo.marketIndex,
			baseAmountOfClose.val,
			estExitPriceOnImmediateExecution
		);

		if (baseAmountOfClose.val.gte(openPositionInfo.baseSize)) {
			newLiquidationPrice = new BN(-1);
		}

		if (newLiquidationPrice.lten(0)) {
			liqPriceVal = 'None';
		} else if (newLiquidationPrice.gt(new BN(1_000_000).mul(PRICE_PRECISION))) {
			liqPriceVal = '>$1M';
		} else {
			const liqPriceNum = NumLib.formatBn.toRawNum(
				newLiquidationPrice,
				PRICE_PRECISION
			);

			const liqPriceUI = NumLib.formatNum.toDisplayPrice(liqPriceNum);
			liqPriceVal = `$${liqPriceUI}`;
		}

		return {
			liquidationPrice: liqPriceVal,
			exitPriceNum: estExitPriceBigNum.toNum(),
			exitPrice: estExitPriceUIVal,
			estPriceImpact: priceImpactUIVal,
			pnlBeforeFees: newEstimatedPnlBeforeFees,
			notionalSizeAtExit: newNotionalSizeAtExit,
			notionalSizeAtEntry: newNotionalSizeAtEntry,
			takerFee: newEstimatedTakerFee,
			realisedPnl: newEstimatedPnl,
		};
	}, [
		priceBoxValue,
		secondaryPriceBoxValue,
		closeSizeNum,
		openPositionInfo.entryPrice.toNumber(),
		isSellPredictionMarket,
	]);

	function getQuoteAmountOfClose() {
		let priceToUse = getQuotePriceToUse(
			selectedOrderType,
			closePositionOrderDirection,
			currentMarkPrice,
			currentOraclePrice,
			prizeBoxValueBigNum,
			currentMarkPrice,
			secondaryPriceBoxValueBigNum
		);

		if (isSellPredictionMarket && selectedOrderType === 'market') {
			priceToUse = MAX_PREDICTION_PRICE_BIGNUM.sub(priceToUse);
		}

		const closeAmountQuoteValue = priceToUse
			.mul(baseAmountOfClose)
			.shiftTo(PRICE_PRECISION_EXP);

		return closeAmountQuoteValue.val;
	}

	return {
		priceBoxHeading,
		showSecondaryPriceBox,
		errorMessageInfo,
		liquidationPrice,
		exitPriceNum,
		exitPrice,
		estPriceImpact,
		pnlBeforeFees,
		notionalSizeAtExit,
		notionalSizeAtEntry,
		realisedPnl,
		takerFee,
		quoteSizeOfClose,
	};
};

export default useClosePositionStateEngine;

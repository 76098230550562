'use client';

import React, { useEffect, useState } from 'react';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { twMerge } from 'tailwind-merge';

// Adds gradient edges indicating scrollable content to the left and right of a scrollable container on mobile
const MobileScrollEdges = ({
	children,
	className,
	/**
	 * Ref to scrollable element within the children to add gradients to
	 */
	scrollableRef,
	/**
	 * Class to apply to the gradient edges
	 * Format is 'from-{color}'
	 */
	edgeColorClass = 'from-button-secondary-bg',
}: {
	children: React.ReactNode;
	className?: string;
	scrollableRef: React.RefObject<HTMLElement>;
	edgeColorClass?: string;
}) => {
	const isMobileScreenSize = useIsMobileScreenSize();
	const [hasLeftContent, setHasLeftContent] = useState(false);
	const [hasRightContent, setHasRightContent] = useState(false);

	const handleScroll = (event: Event) => {
		const target = event.target as HTMLDivElement;
		const hasLeftContent = target.scrollLeft > 0;
		const hasRightContent =
			target.scrollLeft < target.scrollWidth - target.clientWidth;
		setHasLeftContent(hasLeftContent);
		setHasRightContent(hasRightContent);
	};

	useEffect(() => {
		if (scrollableRef.current) {
			const target = scrollableRef.current;
			const hasLeftContent = target.scrollLeft > 0;
			const hasRightContent =
				target.scrollLeft < target.scrollWidth - target.clientWidth;
			setHasLeftContent(hasLeftContent);
			setHasRightContent(hasRightContent);

			target.addEventListener('scroll', handleScroll);

			return () => {
				if (scrollableRef.current) {
					scrollableRef.current.removeEventListener('scroll', handleScroll);
				}
			};
		}
	}, [scrollableRef.current]);

	return (
		<div className={twMerge('relative', className)}>
			{children}

			{hasLeftContent && isMobileScreenSize && (
				<div
					className={`absolute left-0 top-0 h-full w-6 bg-gradient-to-r ${edgeColorClass} to-transparent z-10`}
				/>
			)}
			{hasRightContent && isMobileScreenSize && (
				<div
					className={`absolute right-0 top-0 h-full w-6 bg-gradient-to-l ${edgeColorClass} to-transparent z-10`}
				/>
			)}
		</div>
	);
};

export default React.memo(MobileScrollEdges);

'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	L2OrderBook,
	MarketType,
	PositionDirection,
	ZERO,
} from '@drift-labs/sdk';
import { MarketId, matchEnum, UIMarket } from '@drift/common';
import { useEffect, useMemo, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import useL2StateForMarket from './useL2StateForMarket';
import { useTargetPerpMarketAccount } from './useTargetMarketAccount';
import useWalletIsConnected from './useWalletIsConnected';

export type ShowLiquidityWarningState = {
	state: 'show' | 'hide';
	side: 'bid' | 'ask';
	value: string;
};

const DEFAULT_STATE: ShowLiquidityWarningState = {
	state: 'hide',
	side: 'ask',
	value: '',
};

const getTotalLiquidityFroml2State = (l2State: L2OrderBook) => {
	let [bids, asks] = [ZERO, ZERO];

	l2State.bids.forEach((bid) => {
		bids = bids.add(bid.size);
	});

	l2State.asks.forEach((ask) => {
		asks = asks.add(ask.size);
	});

	return { bids, asks };
};

const useShowLiquidityWarning = (
	marketIndex: number,
	requestedBaseSize: BN,
	requestedTradeSide: PositionDirection
): ShowLiquidityWarningState => {
	const marketAccount = useTargetPerpMarketAccount(
		MarketId.createPerpMarket(marketIndex)
	);

	const l2StateForMarket = useL2StateForMarket(
		marketAccount?.marketIndex,
		MarketType.PERP
	);

	const availablePerpLiquidity = useMemo(() => {
		return getTotalLiquidityFroml2State(l2StateForMarket);
	}, [l2StateForMarket]);

	const requestedBaseSizeBigNum = BigNum.from(
		requestedBaseSize,
		BASE_PRECISION_EXP
	);

	const [state, setState] = useState<ShowLiquidityWarningState>(DEFAULT_STATE);

	useEffect(() => {
		// don't want to show 0 liquidity if having issues loading dlob
		if (
			l2StateForMarket.asks.length === 0 &&
			l2StateForMarket.bids.length === 0
		) {
			setState(DEFAULT_STATE);
			return;
		}

		const liquiditySideToCheck = matchEnum(
			requestedTradeSide,
			PositionDirection.LONG
		)
			? 'ask'
			: 'bid';

		const availableLiquidity =
			liquiditySideToCheck === 'ask'
				? availablePerpLiquidity.asks
				: availablePerpLiquidity.bids;

		if (availableLiquidity.gte(requestedBaseSize)) {
			setState(DEFAULT_STATE);
			return;
		}

		setState({
			state: 'show',
			side: liquiditySideToCheck,
			value: `${BigNum.from(availableLiquidity, BASE_PRECISION_EXP).prettyPrint(
				false,
				8
			)} ${UIMarket.createPerpMarket(marketIndex)?.baseAssetSymbol() ?? ''}`,
		});
		return;
	}, [
		availablePerpLiquidity,
		requestedBaseSizeBigNum?.toNum(),
		l2StateForMarket,
	]);

	return state;
};

export const useShowTradeformLiquidityWarnings =
	(): ShowLiquidityWarningState => {
		const currentMarket = useDriftStore((s) => s.selectedMarket.current);

		const walletIsConnected = useWalletIsConnected();
		const isSubscribed = useDriftClientIsReady();

		const currentBaseSizeStringValue = useDriftStore(
			(s) => s.tradeForm.baseSizeStringValue
		);

		const baseSize = BigNum.fromPrint(
			currentBaseSizeStringValue,
			BASE_PRECISION_EXP
		);

		const tradeFormSide = useDriftStore((s) => s.tradeForm.side);

		const showLiquidityWarning = useShowLiquidityWarning(
			currentMarket.market.marketIndex,
			baseSize.val,
			tradeFormSide === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT
		);

		if (!isSubscribed || !walletIsConnected) {
			return DEFAULT_STATE;
		}

		return showLiquidityWarning;
	};

export default useShowLiquidityWarning;

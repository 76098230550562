import { twMerge } from 'tailwind-merge';
import TradeFormInputLabel from '../TradeForm/TradeFormInputLabel';

const FormInputBox = ({
	children,
	label,
	quickAction = null,
	className,
}: {
	children: React.ReactNode;
	label?: string;
	quickAction?: React.ReactNode;
	className?: string;
}) => {
	return (
		<div className={twMerge('flex flex-col w-full gap-1 flex-1', className)}>
			{label ? <TradeFormInputLabel>{label}</TradeFormInputLabel> : quickAction}
			{children}
		</div>
	);
};

export default FormInputBox;

'use client';

import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { EditOrderPopupInner } from '../Popups/EditOrderPopup';
import Modal from './Modal';

const EditOrderModal = () => {
	const { hideEditOrderModal } = useDriftActions();

	const orderToEdit = useDriftStore(
		(s) => s.popups.editOrderPopupOptions.orderToEdit
	);

	return (
		<Modal onClose={hideEditOrderModal}>
			<Modal.Body>
				<Modal.Header onClose={hideEditOrderModal} showX className="sm:block">
					<Modal.Title>Edit Order</Modal.Title>
				</Modal.Header>
				<div className="text-text-default w-full flex flex-col items-center">
					<EditOrderPopupInner
						existingOrder={orderToEdit}
						onClose={hideEditOrderModal}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EditOrderModal;

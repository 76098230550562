import { ChevronUp } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';

const Chevron = ({
	open,
	className,
	size,
	onClick,
	color,
}: {
	open: boolean;
	className?: string;
	size?: number;
	onClick?: () => void;
	color?: string;
}) => {
	return (
		<ChevronUp
			className={twMerge(
				'w-6 h-6 transition-transform',
				open ? 'rotate-0' : 'rotate-180',
				className
			)}
			size={size}
			onClick={onClick}
			color={color}
		/>
	);
};

export default Chevron;

import { produce } from 'immer';
import { create } from 'zustand';

export type SheetName = keyof Omit<
	SheetsStore,
	'handleCloseSheet' | 'get' | 'set' | 'sheetIsTransitionClosing'
>;

/**
 * SheetStore assumes only 1 sheet can be open at a time.
 */
export interface SheetsStore {
	set: (x: (s: SheetsStore) => void) => void;
	get: (x: any) => SheetsStore;

	/**
	 * If true, the sheet is transitioning to close. This assumes only one sheet can be open at a time.
	 */
	sheetIsTransitionClosing: boolean;

	/**
	 * Handle closing the sheet and setting the state to defaults for that sheet.
	 */
	handleCloseSheet: (sheetKey: SheetName) => void;

	/**
	 * Sheets
	 */
	balanceCardMore: { show: boolean; marketIndex: number };
}

export const useSheetsStore = create<SheetsStore>((set, get) => {
	const immerSet = (fn: (state: SheetsStore) => void) => set(produce(fn));

	return {
		set: immerSet,
		get: () => get(),
		sheetIsTransitionClosing: false,

		balanceCardMore: {
			show: false,
			marketIndex: 0,
		},

		handleCloseSheet: (sheetKey: SheetName) => {
			immerSet((state) => {
				state.sheetIsTransitionClosing = true;
				setTimeout(() => {
					console.log('closed sheet', sheetKey);
					immerSet((state) => {
						state.sheetIsTransitionClosing = false;
						state[sheetKey].show = false;
					});
				}, 300);
			});
		},
	};
});
